import React, { Component } from 'react';

import {Link} from 'react-router-dom';

class NavBar extends Component {
  
  render() {
    return (
      <nav className="topnav">
        <Link to="/" className="floatLeft active" id="navMainPage">✦ Leser.io ✦</Link>
        
        <div className='dropdown'>
            <Link to="/projects" className="floatRight">Projects</Link>
        </div>
        <Link to="/blog" className="floatRight">Blog</Link>
        <Link to="/" className="floatRight">About</Link>

      </nav>
    );
  }

}

export default NavBar;


