import React from 'react';
import './../App.js';
import Star from './../generalComponents/Star.js';
import HeadingBar from './../generalComponents/HeadingBar.js';
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';

function Ratebit() {

    const imageStyle = {
        width: '80vw'
    };

    return (
        <div className="paperBackground">
            <HeadingBar text="Ratebit" image="/img/RatebitBanner.png"/>

            <p className='paperFull'>
                <Star />
                Ratebit is a recommendation app. It let's you define the algorithm system that gives you content.
                <Star />
                It can be found under: <br/><a href="https://www.ratebit.io/">Ratebit.io</a><br/>
                I would love if you checked out all of my channels or joined the conversation:<br/> <a href="https://linktr.ee/ratebit">Linktr.ee/ratebit</a><br/>
                <Star />

                I made Ratebit for many many reasons, but I shared some of them here:<br/>
                <Link to="/blog/attention" className="navStyle">Thinking About Attention</Link>

                <Star />

            </p>
        </div>
        
    );
}

export default Ratebit;