import React from 'react';
import './../App.js';
import {Link} from 'react-router-dom';
import HeadingBar from '../generalComponents/HeadingBar.js';

function Projects() {
    return (
        <div className='paperBackground'>
            <HeadingBar text="Projects" image="/img/stars.gif"/>
            <div className='paper'>
                <h2>Ratebit</h2>
                <p>
                    I'm making the recommendation app of my dreams<br/>
                </p>
                <nav className="projectsButton"><Link to="/projects/ratebit" className="navStyle">Ratebit</Link><br/></nav><br/> 
            </div>
            <div className='paper'>
                <h2>Boleite</h2>
                <p>
                    A weird youtube client with custon recommendations <br/>and full control over your data<br/>
                </p>
                <nav className="projectsButton"><Link to="/boleite" className="navStyle">Boleite</Link><br/></nav><br/> 
            </div>
            <div className='paper'>
                <h2>8-Bit Quantum</h2>
                <p>
                    A website that explains Quantum computing from a computer science standpoint<br/>
                </p>
                <nav className="projectsButton"><Link to="/projects/8BitQuantum" className="navStyle">8-Bit Quantum</Link><br/></nav><br/> 
            </div>
            <div className='paper'>
                <h2>Maroon</h2>
                <p>
                    A fun Andriod game using the gyroscope sensor <br/>where you can compete on the global leaderboards<br/>
                </p>
                <nav className="projectsButton"><Link to="/projects/maroon" className="navStyle">Maroon</Link><br/></nav><br/>
            </div>
            <div className='paper'>
                <h2>Other projects</h2>
                <p>
                    Some other projects can be found here<br/>
                </p>
                <a href='https://github.com/JulianLeser'>My Github page</a><br/><br/>
            </div>

        </div>
    );
}

export default Projects;