import React from 'react';
import './../App.js';
import Star from './../generalComponents/Star.js';
import HeadingBar from './../generalComponents/HeadingBar.js';

function _8BitQuantum() {

    const imageStyle = {
        width: '80vw'
    };

    return (
        <div className="paperBackground">
            <HeadingBar text="8-Bit Quantum" image="/img/stars.gif"/>

            <p className='paperFull'>
                <Star />
                8-Bit Quantum is a website that explains Quantum computing from a computer science background.<br/>
                I programmed and designed it when working for Elyah, a great Quantum-Computing company.<br/>
                <Star />
                It also includes a Bloch Sphere simulator that runs in the browser (Shortcut to open: "b" button).<br/>
                It has 5 Chapters, each filled with explanations and exercises that can be tried out in the embedded Qubit Workbench by Elyah.<br/>
                <Star />
                <a href="http://web.archive.org/web/20210305103546/https://8bitquantum.com/">Link to 8-Bit Quantum (Web Archive Link due to the domain running out)</a><br/>
                <a href="https://www.elyah.io/">Link to Elyah</a><br/>
                <Star />

            </p>
        </div>
        
    );
}

export default _8BitQuantum;