import React from 'react';
import './../App.js';
import {Link} from 'react-router-dom';
import HeadingBar from '../generalComponents/HeadingBar.js';
import Star from './../generalComponents/Star.js';

import fullScreenshot from '../img/FullScreenshotDisplay1.png';
import searchBar from '../img/searchBarT.png';
import ratingOptions from '../img/BoleiteRatingEdited.png';
import boleiteShutdown from '../img/BoleiteShutdown.png';
import focusBar from '../img/focusBarT.png';
import focusBar1 from '../img/focusBar1T.png';
import diagram from '../img/BoleiteDiagram.png';
import boleiteLogo from '../img/Boleite1024.png';

function Boleite() {
    return (
        <div className='boleite'>

            <div id='boleiteSetupButton'><Link to="/boleite/setup" className="navStyle zindex5">Setup</Link></div>

            <div className="boleiteBar" style={{ 
                backgroundImage: `url(../img/stars.gif)`
            }}><img src={boleiteLogo} alt="Boleite Logo" width={"15%"} className="zindex5"/><br/>
            <div className='boleiteHeading zindex5'>Boleite</div>
            
            <p className="zindex5">
                A weird Youtube client with two input bars and custom recommendations
            </p></div>
            <img src={fullScreenshot} alt="Boleite Screenshot" width={"70%"}/><br/>
                
            <div className='boleiteSubHeading'>✦ Why? ✦</div>
            <p>
                Your Youtube homepage is a mix of many things you may not all want displayed at all times.<br/>
                Boleite creates an alternative homepage based on a category you can change at any time.<br/>
                + you can change the homepage algorithm completely freely.
            </p>
            
            <div className='boleiteSubHeading'>✦ What? ✦</div>
            
            <div className='boleiteCard'>
                User defined rating options:<br/><br/>
                <img src={ratingOptions} alt="Boleite Rating options" width={"65%"}/>
                <p>
                    After watching a video you liked, rate it according to the categories you defined.
                </p>
            </div>
            <div className='boleiteCard'>
            The focus bar:<br/>
                <img src={focusBar} alt="Boleite Focusbar" width={"45%"}/>
                <p>
                    Allows you to enter the category of videos you want your homepage to look like and what minimum rating they need on that category.<br/>
                    Which could look like this:
                </p>
                <img src={focusBar1} alt="Boleite Focusbar with 'usefulness:8' entered" width={"45%"}/>
            </div>
            <div className='boleiteCard'>
                A regular search bar: <br/>
                <img src={searchBar} alt="Boleite Seachbar" width={"85%"}/><br/>
                <p>
                    Does what any regular search bar does.
                </p>
            </div>
            
            

            <div className='boleiteSubHeading'>✦ How? ✦</div><br/>
            <img src={diagram} alt="Diagram about how boleite works" width={"45%"} style={{ 
                borderRadius: "30px"
            }}/>
            <p>
                You can rate videos from 1-10 on categories you can define.<br/>
                Boleite then sends a request to get videos similar to the one you rated.<br/>
                This data is all stored locally so you can delete it whenever you feel like it.<br/>
                (This also makes it possible to send your data to a friend who will then have the same homepage.)<br/>
                An algorithm then runs locally and creates your homepage based on this data/metadata.<br/>
                Technically proficient users can even change this algorithm completely freely.
            </p><br/>

            <div className='boleiteCard'>
                Currently broken due to Youtube API change<br/><br/>
                <img src={boleiteShutdown} alt="Boleite broken due to Youtube API changes" width={"65%"}/>
                <p>
                    The project heavily relies on the relatedToVideoId Parameter of the Youtube API, which was removed on the 7th of August 2023.
                    I would love to rebuild the project with different technologies that don't rely on the Youtube API, so I hope to come back to this at some point.
                </p>
            </div>

        </div>
    );
}

export default Boleite;