import React, { Component } from 'react';

import {Link} from 'react-router-dom';

class PrivacyPolicyLink extends Component {
  
  render() {
    return (
      <div>
        <Link to="/privacy-policy" className="privacyPolicyLink">Privacy Policy</Link>
      </div>
    );
  }

}

export default PrivacyPolicyLink;


