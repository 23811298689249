import React from 'react';
import './../App.js';
import {Link} from 'react-router-dom';
import HeadingBar from '../generalComponents/HeadingBar.js';

function Blog() {
    return (
        <div className='paperBackground'>
            <HeadingBar text="Blog" image="/img/stars.gif"/>

            <div className='paper'>
                <h2>Thinking About Attention</h2>
                <p>
                    A conversation about attention, and what Ratebit has to do with that<br/>
                </p>
                <nav><Link to="/blog/attention" className="navStyle">Full post</Link><br/></nav><br/>
                2024-09-03
            </div>
            <div className='paper'>
                <h2>This site</h2>
                <p>
                    A personal website for mostly computer science related things<br/>
                </p>
                <nav><Link to="/blog/site" className="navStyle">Full post</Link><br/></nav><br/> 
                2022-07-04
            </div>
        </div>
    );
}

export default Blog;