import './App.css';
import './Scrollbar.css';
import NavBar from './Navbar';
import Blob from './Blob';
import BoleiteNav from './BoleiteNav';

import Home from './Home';
import Projects from './Projects/Projects';
import PrivacyPolicy from './PrivacyPolicy';

import Maroon from './Projects/Maroon';
import _8BitQuantum from './Projects/8BitQuantum';
import Boleite from './Projects/Boleite';
import BoleiteSetup from './Projects/BoleiteSetup';
import Ratebit from './Projects/Ratebit';

import Blog from './Blog/Blog';
import ThisSite from './Blog/ThisSite';
import Attention from './Blog/Attention';

import PrivacyPolicyLink from './generalComponents/PrivacyPolicyLink';

//import woodCircle from './wood.png';
import {HashRouter as Router, Switch, Route} from 'react-router-dom';

function App() {
  return (
    <Router>
    <div className="App">

    <Switch>
      <Route path="/boleite" component={BoleiteNav}></Route>
      <Route path="/" component={NavBar}></Route>
    </Switch>
    <Switch>
      <Route path="/" component={Blob}></Route>
    </Switch>

    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/projects" exact component={Projects} />

      <Route path="/projects/Ratebit" component={Ratebit} />
      <Route path="/projects/maroon" component={Maroon} />
      <Route path="/projects/8BitQuantum" component={_8BitQuantum} />
      <Route path="/boleite" exact component={Boleite} />
      <Route path="/boleite/setup" exact component={BoleiteSetup} />

      <Route path="/blog" exact component={Blog} />
      <Route path="/blog/site" component={ThisSite} />
      <Route path="/blog/attention" component={Attention} />

      <Route path="/privacy-policy" component={PrivacyPolicy} />
    </Switch>
    
    <PrivacyPolicyLink />
    </div>
    </Router>
    
  );
}

export default App;
