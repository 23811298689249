import React from 'react';
import '../App.js';
import {Link} from 'react-router-dom';

import zipFiles from '../img/ExtractedDirectory.png';
import focusBarSettings from '../img/focusBarSettings.png';
import apiKeyInput from '../img/apiKeyInput.png';
import boleiteLogo from '../img/Boleite1024.png';

function Boleite() {
    return (
        <div className='boleite'>

            <div id='boleiteSetupButton'><Link to="/boleite" className="navStyle zindex5">Back</Link></div>

            <img src={boleiteLogo} alt="Boleite Logo" width={"15%"} className="zindex5"/><br/>
            <div className='boleiteHeading zindex5'>Boleite Setup</div>
            <p className="zindex5">
                This should take &#60; 5 minutes
            </p>
                
            <div className='boleiteSubHeading'>✦ Step 1 ✦</div>
            <p>
                Dowload the zip and extract it.<br/><br/>

                <a href='https://github.com/JulianLeser/Boleite' style={{color: "#ffffff"}}>Download from Github</a><br/><br/>

                You should now have a folder with these files.<br/><br/>
                <img src={zipFiles} alt="Boleite Focusbar with 'usefulness:8' entered" width={"13%"}/><br/><br/>
                Open Boleite with the .exe, or create a shortcut to it.<br/>
                The Data folder is important for advanced users but can be ignored for now.
            </p>
            
            <div className='boleiteSubHeading'>✦ Step 2 ✦</div>
            <p>
                Create a Youtube API key like this:<br/>
                <a href='https://developers.google.com/youtube/v3/getting-started' style={{color: "#ffffff"}}>Google developers link</a>
            </p>   

            <div className='boleiteSubHeading'>✦ Step 3 ✦</div>
            <p>
                Paste the key into its input box<br/><br/>
                <img src={focusBarSettings} alt="Settings button" width={"25%"}/><br/>
                <img src={apiKeyInput} alt="the API key input box" width={"65%"}/>
            </p>            

            <div className='boleiteSubHeading'>✦ Step 4 (Optional)✦</div>
            <p>
                Paste a custom filter or algorithm into the input boxes in the same menu as the key.
            </p><br/>
            <div className='boleiteSubHeading'>✦ Step 5 (Very very optional)✦</div>
            <p>
                Maybe donate? :))))<br/>
                <a href='https://www.paypal.com/donate/?hosted_button_id=JDYE6AVEKZUH2' style={{color: "#ffffff"}}>Via Paypal</a>
            </p><br/>

        </div>
    );
}

export default Boleite;