import React from 'react';
import './../App.js';
import Star from './../generalComponents/Star.js';
import HeadingBar from './../generalComponents/HeadingBar.js';
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';

function Attention() {

    const imageStyle = {
        width: '80vw'
    };

    return (
        <div className="paperBackground">
            <HeadingBar text="Thinking About Attention" image="/img/RatebitBanner.png"/>

            <p className='paperFull'>
                <Star />

                I want to talk to you about attention.<br/>
                I know 5 minutes of reading time is a lot to ask given modern attention spans, but please give it a shot.<br/>
                
                <Star />
                A couple of years ago, I watched this: <br/>
                <a href="https://youtube.com/watch?v=wf2VxeIm1no">CGP Grey: Thinking About Attention -- Walk with me</a><br/><br/>
                <iframe
                    width="640"
                    height="360"
                    padding="20"
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    src="https://youtube.com/embed/wf2VxeIm1no">
                </iframe><br/><br/>
                It's a video that drastically changed my outlook on media.<br/>
                After deciding that I at least want a filter on top of what Youtube shows me,
                I built <Link to="/boleite" className="navStyle">Boleite</Link>.<br/><br/>

                Youtube turned off access to the data needed for Boleite to work at the end of 2023, which made the project defunct.<br/><br/>
                
                About a year later, <Link to="/projects/ratebit" className="navStyle">Ratebit</Link>, 
                a spiritual successor to Boleite, is now close to being done. 
                Except this time a social media giant won't be able to turn it off.

                <Star />

                <h1>1. The fight for your attention</h1>
                The competition for your attention used to be very slim.<br/><br/>
                Before writing became established, you could only listen to someone in person.<br/><br/>
                With the introduction of written word, the competition was upped, now a person telling you something also had to compete with whatever books you owned.<br/><br/>
                Television suddenly made passive consumption of content mainstream, so your attention could be spent much more comfortably.<br/><br/>
                With the introduction of the internet, suddenly the information was endless, and the library of items to spent your attention on had to be catalogued by search engines.<br/>
                This information was endless, yes, but you had to explicitly search for it, so some conscious thought was needed.<br/><br/><br/>
                The last evolutionary wave of technology is personalised recommendation algorithms.<br/>
                Now, the social media platforms measure the time you spend on each piece of content and use that to feed the giant machine learning models that sole job it is to keep you hooked.<br/>
                You don't even have to do anything except for looking at content, and the machine knows what will make you continue watching.<br/>

                <Star />

                <h1>2. The secret algorithms that drive the world</h1>
                They are: closed source, optimized to keep you glued to the screen and not optimized to be actually helpful

                <h2>2.1 They eat your attention</h2>
                The business model that underlies almost every social media platform is advertising.<br/>
                That means the longer you watch, the more ads can be shoved in front of your face.<br/><br/>

                Being more addictive than the other platforms makes a platform more lucrative, which increases the chance it will survive in the marketplace.<br/>
                I hope that we can reject the most predatory systems (like the watchtime optimizing algorithms), so less addictive forms of content will have a place to flourish.

                <h2>2.2 Millions of small nudges and the illusion of choice</h2>
                Every day, most people get thousands of pieces of content delivered to them by social media companies.<br/><br/>

                You might think that you are in control of the things you watch, but in reality, 
                when you see fifty preselected pieces of content in your feed and only one pick from them, 
                your contribution is often subconcious at best, not the kind of deliberate thought 
                that would get you to engage with something you really want to spend your time on.<br/><br/>

                You might think that the algorithms only want to show you "the things you like watching", but this is far from true.
                They use watchtime data to measure "engagement", which is really just a rebranding of addiction in the most cases.<br/>
                The result of this data source, is that these platforms find the content that will keep you sticking around the longest, not the content you are happy to have watched.<br/><br/>

                This engagement can even be directed by those companies without you knowing. <a href='https://www.bbc.com/news/av/technology-44684524'>Manipulation is built in.</a>

                <h2>2.3 Well kept secrets</h2>

                The algorithms the social media giants use are all secret. Well, the Twitter/X algorithm is open sourced, 
                but the machine learning model it uses relies on "weights", so inputs on what things to boost or deplatform, which are kept secret.<br/>
                If the algorithms were public, there would be room to critique them, so they keep their lips shut.

                <Star />

                <h1>3. How to turn the algorithms off</h1>
                I encourage everyone reading this to turn all of their recommendation algorithms off, at least for a week.<br/>
                If you think this was a good decision feel free to keep it that way :)<br/><br/>
                The places you mostly see recommendation algorithms are social media apps and their respective websites. So let's get started removing their algorithms.<br/>

                <h2>3.1 Websites</h2>
                A really great thing about websites is that they are really easily editable with browser extensions.
                <ul>
                    <li>Youtube: <a href="https://unhook.app/">Unhook</a></li>
                    <li>Twitter/X: <a href="https://jbscript.dev/control-panel-for-twitter">Control panel for Twitter</a></li>
                    <li>Reddit: <a href="https://chromewebstore.google.com/detail/reddit-liberation-feed-bl/fllfmdjhnhhjokhdifhcdbpbfajfnhon">Reddit Liberation</a></li>
                    <li>TikTok (I made this one myself in like 10 minutes): <a href="https://github.com/JulianLeser/TikTok-Feed-Remover/">Tiktok Feed Remover (Google how to install an unpacked local chrome extension)</a></li>
                    <li>General purpse site blocker: <a href="https://www.proginosko.com/leechblock/">Leechblock</a></li>
                </ul>

                <h2>3.2 Apps</h2>
                Most mobile apps can't be easily edited, so as annoying as it may be, just uninstall them.<br/>
                You can then still use them with a web browser. Just keep in mind that you will need a mobile browser that supports extensions.<br/>
                Please do your own reasearch for these, but I personally recommend <a href="https://kiwibrowser.com/">Kiwi Browser</a> (which supports Chrome extensions) or <a href="https://www.mozilla.org/en-US/firefox/browsers/mobile/">Firefox</a><br/>

                <Star />

                <h1>4. But I like recommendations!</h1>
                <Link to="/projects/ratebit" className="navStyle">Ratebit</Link>&nbsp;is my best attempt at making
                 a recommendation platform that doesn't try to keep you watching for profit. The algorithm is completely open, and you can even fully edit it yourself.<br/>
                I love getting content recommendations from people, so this platform makes the whole process more human and doesn't include any watchtime measurements.<br/>

                <Star />

                If you have read this far, I invite you to join the <a href="https://discord.gg/ZWJv7sXZ68">Ratebit Discord Server</a> or the <a href="https://www.reddit.com/r/ratebit/">Ratebit Subreddit</a>!<br/>
                Social media diet, how we choose to spend our attention and what steps we take to protect it have real importance, so give them the thought they deserve.

                <Star />

                2024-09-03

            </p>
        </div>
        
    );
}

export default Attention;