import React from 'react';
import './../App.js';

function Star(props) {

    return (
        <div className="star"><h2 className="starHeading">✦</h2></div>
    );
}

export default Star;