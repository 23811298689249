import React, { Component } from 'react';

import {Link} from 'react-router-dom';

class NavBar extends Component {
  
  render() {
    return (
      <nav className="boleitenav">
        <Link to="/" className="floatLeft active zindex5" id="navMainPage">✦ Leser.io ✦</Link>
      </nav>
    );
  }

}

export default NavBar;


