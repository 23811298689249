import React from 'react';
import '../App.js';
import {Link} from 'react-router-dom';
import HeadingBar from '../generalComponents/HeadingBar.js';
import Star from '../generalComponents/Star.js';

function ThisSite() {
    const imageStyle = {
        width: '80vw'
    };

    return (
        <div className="paperBackground">
            <HeadingBar text="This site" image="/img/stars.gif"/>

            <p className='paperFull'>
                <Star />
                This site is mainly here to act as a portfolio and place to showcase my projects.<br/>
                <Star />

                2022-07-04

            </p>
        </div>
        
    );
}

export default ThisSite;