import React from 'react';
import './App.js';
import HeadingBar from './generalComponents/HeadingBar.js';
import {Link} from 'react-router-dom';
import NorhternLights from './generalComponents/NorthernLights.js';

function Home() {
    return (
        <div>
          <div>Hi! I'm Julian.</div><br/>
          <NorhternLights />
          <div className='paperFull'><br/>
          I make stuff with computers.<br/>
            (See my <Link to="/projects">Projects</Link>)<br/><br/><br/>
            Problems I'm looking to work on:
            <ul>
              <li>Improving the way we use the internet</li>
              <li>Giving people control over the algorithms that control them</li>
              <li>AI Safety and Interpretability</li>
            </ul>
            Stuff I'm good at:
            <ul>
              <li>Problem solving</li>
              <li>Learning new technologies quickly</li>
              <li>Consistency</li>
            </ul>
            Currently focused on:
            <ul>
              <li>Ratebit, a custom recommendation app</li>
            </ul><br/><br/>
            <p>If you're interested in hiring me, here's my CV!</p>
            <div className="aboutButton"><a href='./Resume_Leser.pdf' download className="navAbout">Get CV</a></div><br/><br/>
          </div>
        </div>
    );
}

export default Home;