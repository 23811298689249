import React, { Component } from 'react';
import { forwardRef } from 'react';

class Blob extends Component {

    state = {
        clientX: 0,
        clientY: 0,
      };

    componentDidMount() {
        const handleMouseMove = (event) => {
            this.setState(prevState => ({
                clientX: event.clientX,
                clientY: event.clientY
            }));
           document.getElementById("blob").animate({
            left: event.clientX + "px",
            top: event.clientY + "px"
           }, {duration: 3000, fill: 'forwards'});
        };
        window.addEventListener('mousemove', handleMouseMove);
    }
  
  render() {
    
    

    return (
        <div>
            <div id="blob">
                <div className='blob'></div>
            </div>
            <div id="blur"></div>
        </div>
    );
  }

}

export default Blob;


