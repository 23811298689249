import React from 'react';
import './../App.js';
import Star from './../generalComponents/Star.js';
import HeadingBar from './../generalComponents/HeadingBar.js';

function Maroon() {

    const imageStyle = {
        width: '20vw'
    };

    return (
        <div className="paperBackground">
            <HeadingBar text="Maroon" image="https://play-lh.googleusercontent.com/evOtmS9lPxqCdXrRM4JxbYhTtrgrXrDtGLDZNvHm9A2dU9b6fcf8fWEJm0ntyKoDcbg=w3840-h1938-rw"/>

            <p className='paperFull'>
                <Star />
                Maroon is an Android game available on the Play Store.<br/>

                The objective of the game is to dodge obstacles by rotating your phone.<br/>
                The phone's gyrometric data is used to keep a ball "stationary" even if the user rotates their phone.<br/>
                <Star />
                It also has local and global highscores so you can compete with your friends.<br/>
                <a href="https://play.google.com/store/apps/details?id=com.Saltix.Maroon">Download Link</a><br/>
                <Star />
                <img src="https://play-lh.googleusercontent.com/rwQ6xrGS_nNDxx1wR6E2NZd0rzgOMZ2b0Gw4_P3xlc4BjGjHPb06DmFjWP76NJYcEfy1=w3840-h1938-rw" alt="Screenshot 1" style={imageStyle}></img>
                <img src="https://play-lh.googleusercontent.com/z2fQOyt_0l7B_5vmoZi5NlvD8SeGW8k9L8sMpoKFzH48S0Hw4ueq5w32kNV37nTmVg=w3840-h1938-rw" alt="Screenshot 2" style={imageStyle}></img>
                <img src="https://play-lh.googleusercontent.com/0R2OAhYd3qrHszOsxbMnwxD0tII3Gc9TJvhrhZ0eUPJw1ds7rB66viM6MkpwYiVTXZEd=w3840-h1938-rw" alt="Screenshot 3" style={imageStyle}></img>
                <img src="https://play-lh.googleusercontent.com/evOtmS9lPxqCdXrRM4JxbYhTtrgrXrDtGLDZNvHm9A2dU9b6fcf8fWEJm0ntyKoDcbg=w3840-h1938-rw" alt="Screenshot 4" style={imageStyle}></img>
            </p>
        </div>
        
    );
}

export default Maroon;