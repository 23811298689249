import React from 'react';
import './../App.js';

function HeadingBar(props) {

    return (
        <div className="headingBar" style={{ 
            backgroundImage: `url(${props.image})`
        }}><h1 className="headingBar">{props.text}</h1></div>
    );
}

export default HeadingBar;