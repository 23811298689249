import React, { Component } from 'react';

import ligthsCircle from './../img/lights-circle-low-res.png';

class NorhternLights extends Component {

    constructor(props) {
        super();
        this.state = {
            isHovered: false
        };
        this.handleHover = this.handleHover.bind(this);
    }
    handleHover(){
        this.setState(prevState => ({
            isHovered: !prevState.isHovered
        }));
    }
  
    render() {
        const imageClass = this.state.isHovered ? "activatedLights" : "";
        return (
            <div>
                <div className={imageClass}></div>
                <img className="hoverImage" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover} src={ligthsCircle} alt="Picture of me with the northern liughts" height={350}/>
            </div>
        );
    }

}

export default NorhternLights;


